import React, { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CheckIcon } from "@heroicons/react/solid";
import {
  bearerToken,
  sendMailerUrl,
  captchaInfoUrl,
  initialFormFields,
} from "../../utils/mailer";

const ContactSection = ({
  title,
  description,
  subjectMail = "Request from Contacts",
  nameLabel,
  emailLabel,
  phoneLabel,
  messageLabel,
  submitButton,
  submitButtonSuccess,
  imageName,
  alertTitle = "Thank you!",
  alertText = "We`ve received your message. Someone from our team will contact you soon",
}) => {
  const recaptchaRef = useRef();

  const [formFields, setFormFields] = useState(initialFormFields);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const [formIsSent, setFormIsSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const [siteKey, setSiteKey] = useState("");

  const getCaptchaInfo = async () => {
    const resp = await fetch(captchaInfoUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
    }).catch((err) => {
      new Error(err);
    });

    const data = await resp.json();

    setSiteKey(data.site_key);
  };

  useEffect(() => {
    getCaptchaInfo();
  }, []);

  const setName = (event) => {
    setFormFields({ ...formFields, name: event.target.value });
  };
  const setPhone = (event) => {
    setFormFields({ ...formFields, phone: event.target.value });
  };
  const setEmail = (event) => {
    setFormFields({ ...formFields, email: event.target.value });
  };
  const setMessage = (event) => {
    setFormFields({ ...formFields, message: event.target.value });
  };

  const onSubmit = async () => {
    const response = await fetch(sendMailerUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({
        ...formFields,
        subject: subjectMail,
        recaptcha: recaptchaValue,
      }),
    });
    setFormIsSent(true);

    if (response.status !== 200) {
      setIsError(true);
    } else {
      setFormFields(initialFormFields);
    }
  };

  return (
    <div className="py-8 sm:py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute right-full top-1/4 transform -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                fill="currentColor"
                className="text-gray-400"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute left-full transform translate-x-1/4"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-900">{description}</p>
        </div>
        <div className="mt-12">
          {formIsSent && (
            <div
              className={`rounded-md p-4 mb-6 ${
                isError ? "bg-red-50" : "bg-green-50"
              }`}
            >
              <div className="flex">
                <div className="ml-3">
                  <h3
                    className={`text-sm font-medium ${
                      isError ? "text-red-800" : "text-green-800"
                    }`}
                  >
                    {isError ? "Error!" : alertTitle}
                  </h3>
                  <div
                    className={`mt-2 text-sm ${
                      isError ? "text-red-700" : "text-green-700"
                    }`}
                  >
                    <p>{isError ? "Please, try again" : alertText}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <form className="grid grid-cols-1 gap-y-6">
            <div>
              <div class="flex gap-2">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-900"
                >
                  {nameLabel}
                </label>
                <span id="name" class="text-md text-red-500">
                  *
                </span>
              </div>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={setName}
                  autoComplete="given-name"
                  className="py-3 px-4 block w-full shadow-sm focus:outline-none ring-0 ring-offset-2 ring-offset-amber-500 border-amber-500 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div class="flex gap-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  {emailLabel}
                </label>
                <span id="email" class="text-md text-red-500">
                  *
                </span>
              </div>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={setEmail}
                  autoComplete="email"
                  className="py-3 px-4 block w-full shadow-sm focus:outline-none ring-0 ring-offset-2 ring-offset-amber-500 border-amber-500 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div class="flex gap-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-gray-900"
                >
                  {phoneLabel}
                </label>
                <span id="phone-number" class="text-md text-red-500">
                  *
                </span>
              </div>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="phone-number"
                  id="phone-number"
                  autoComplete="tel"
                  onChange={setPhone}
                  className="py-3 px-4 block w-full focus:outline-none ring-0 ring-offset-2 ring-offset-amber-500 border-amber-500 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div class="flex gap-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-900"
                >
                  {messageLabel}
                </label>
                <span id="message" class="text-md text-red-500">
                  *
                </span>
              </div>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  onChange={setMessage}
                  className="py-3 px-4 block w-full shadow-sm focus:outline-none ring-0 ring-offset-2 ring-offset-amber-500 border-amber-500 rounded-md"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              {formFields.name &&
                formFields.email &&
                formFields.phone &&
                !isVerified && (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    badge={"inline"}
                    sitekey={siteKey}
                    onErrored={() => setIsVerified(false)}
                    onExpired={() => setIsVerified(false)}
                    onChange={(value) => {
                      setRecaptchaValue(value);
                      setIsVerified(true);
                    }}
                  />
                )}

              {!formIsSent || isError ? (
                <button
                  type="button"
                  onClick={onSubmit}
                  disabled={
                    !formFields.name ||
                    !formFields.email ||
                    !formFields.phone ||
                    !formFields.message
                  }
                  className="block w-full justify-center items-center py-3 px-4 rounded-md shadow text-white bg-amber-500 hover:bg-amber-600 font-medium focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-amber-700 focus:ring-offset-gray-900 disabled:bg-gray-200 disabled:text-gray-700"
                >
                  {submitButton}
                </button>
              ) : (
                !isError && (
                  <button
                    type="submit"
                    className="inline-flex justify-center items-center py-3 px-4 rounded-md shadow bg-white text-amber-600 font-medium"
                  >
                    {submitButtonSuccess}
                    <CheckIcon className="h-6 w-6 ml-2 " />
                  </button>
                )
              )}
            </div>
          </form>
          {/* <div className="pt-8 lg:pt-12">
            <GatsbyImage
              image={useImageForData(imageName)}
              alt="Axxpay"
              className="w-full h-full object-cover sepia-[0.4]"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
