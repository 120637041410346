import React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import ContactSection from "../components/sections/ContactSection";
import Footer from "../components/sections/Footer";

const ContactsEnPage = () => (
  <div className="bg-white niramit-regular">
    <PageTitle pageTitle={"Contact Axxtrans"} description={``} />
    <Header lang="en" />
    <ContactSection
      title="Contact AxxPay"
      description="Leave your question in this form and we will contact you as soon as possible"
      subjectMail="Short contact"
      nameLabel="Name"
      emailLabel="Email"
      phoneLabel="Phone"
      messageLabel="Message"
      submitButton="Submit"
      submitButtonSuccess="Sent"
      alertTitle="Thank you!"
      alertText="We`ve received your message. Someone from our team will contact you soon"
    />
    <Footer lang="en" />
  </div>
);

export default ContactsEnPage;
